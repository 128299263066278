import {
  getCardTitle,
  removeTitle,
} from '@gammatech/lib/dist/prosemirror-helpers'
import { Editor, JSONContent } from '@tiptap/core'
import debounce from 'lodash/debounce'
import { Node as ProsemirrorNode, Schema } from 'prosemirror-model'
import { useEffect } from 'react'

import { Card } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { useAppDispatch } from 'modules/redux'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'

import { reset, setCards, CardTextMap, setCardText } from './reducer'

const COMPUTE_TEXT_TITLE_DEBOUNCE = 1000

export const useSyncCardsRedux = (cards: Card[] = [], editor?: Editor) => {
  const dispatch = useAppDispatch()
  const cardTextComputedEnabled = useFeatureFlag('cardTextComputedEnabled')

  useEffect(() => {
    if (!editor || !cardTextComputedEnabled) return

    const cb = debounce(
      () => {
        const localCardTextMap: CardTextMap = {}
        editor.state.doc.descendants((node, pos) => {
          if (isCardNode(node) && node.attrs.id) {
            const jsonContent = node.toJSON() as JSONContent
            const title = getCardTitle(jsonContent)
            const $pos = editor.state.doc.resolve(pos)
            const isFirstCard = $pos.parentOffset === 0 && $pos.depth === 1

            localCardTextMap[node.attrs.id] = {
              title,
              text: getCardText(editor.schema, jsonContent, title),
              isFirstCard,
            }
          }
          return true
        })
        dispatch(setCardText({ localCardTextMap }))
      },
      COMPUTE_TEXT_TITLE_DEBOUNCE,
      {
        leading: true,
        trailing: true,
        maxWait: 1000,
      }
    )

    cb()

    editor.on('update', cb)
    return () => {
      editor.off('update', cb)
    }
  }, [editor, dispatch, cardTextComputedEnabled])

  useEffect(() => {
    if (!cards) return
    dispatch(setCards({ cards }))
  }, [dispatch, cards])

  // Cleanup when we unmount
  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [])
}

// Copy of getCardText from @gammatech/lib/dist/prosemirror-helpers
// because using the import directly causes errors
const getCardText = (
  schema: Schema,
  proseMirrorNodeJSON: JSONContent,
  title?: string
): string => {
  // replace nested cards with a paragraph with the text of their titles
  const nodeCopy = { ...proseMirrorNodeJSON }
  nodeCopy.content = nodeCopy.content?.map((node) => {
    if (node['type'] !== 'card') return node
    const childTitle = getCardTitle(node)
    return {
      type: 'paragraph',
      // ProseMirror doesn't allow empty next nodes, so better to have no text node at all
      content: childTitle ? [{ type: 'text', text: childTitle }] : [],
    }
  })

  const node = ProsemirrorNode.fromJSON(schema, nodeCopy)
  const text = node.textBetween(
    0,
    node.content.size,
    '\n\n',
    (leafNode: ProsemirrorNode) => {
      const nodeType = leafNode.type.name
      if (nodeSerializers[nodeType]) {
        return nodeSerializers[nodeType](leafNode) || ' '
      }
      return ' '
    }
  )
  if (title) {
    return removeTitle(text, title)
  }
  return text
}

const nodeSerializers: Record<string, (node: any) => string | undefined> = {
  text: (node) => node.text,
  emoji: (node) => node.attrs.native,
  embed: (node) => {
    const { meta, sourceUrl, url } = node.attrs
    return meta?.title || sourceUrl || url || undefined
  },
  mention: (node) => '@' + node.attrs.label,
  video: (node) => {
    const { meta, sourceUrl, embedUrl } = node.attrs
    return meta?.title || sourceUrl || embedUrl || undefined
  },
}
