// It's pretty easy to get into a state where the contentful Graphql server returns
// an error along with valid data (this is what's known as a partial graphql error).
// This can happen if someone adds a deck to a category, then unpublishes the deck.
// We don't want the app to fail because of this!
// https://contentful-community.slack.com/archives/CAVCST7DH/p1648799533290609?thread_ts=1648548664.434629&cid=CAVCST7DH
// https://www.contentful.com/developers/docs/references/graphql/#/reference/graphql-errors/graphql-errors-explained
import { ApolloError } from '@apollo/client'
import every from 'lodash/every'

import { DEFAULT_GAMMA_LOCALE } from 'modules/i18n/constants'

export const canSafelyIgnoreErrors = (error?: ApolloError) => {
  if (
    error?.graphQLErrors &&
    error?.graphQLErrors?.length > 0 &&
    every(error?.graphQLErrors, (err) => {
      return err?.extensions?.contentful?.code === 'UNRESOLVABLE_LINK'
    }) &&
    error?.clientErrors.length === 0 &&
    !error?.networkError
  ) {
    return true
  }
  return false
}

const DEFAULT_CONTENTFUL_LOCALE_ENGLISH = 'en-US'

export type ContentfulLocale = 'en-US' | 'ko'

/**
 * Ensures we dont pass an invalid locale to contentful, as the code must match exactly.
 * https://app.contentful.com/spaces/yg6st2np8vj7/settings/locales
 */
export const getContenfulSafeLocale = (locale: string): ContentfulLocale => {
  // US English
  if (locale === DEFAULT_GAMMA_LOCALE) {
    return DEFAULT_CONTENTFUL_LOCALE_ENGLISH
  }
  // Korean
  if (locale === 'ko') {
    return 'ko'
  }

  return DEFAULT_CONTENTFUL_LOCALE_ENGLISH
}
