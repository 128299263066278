import { Flex } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import { Dispatch, SetStateAction } from 'react'

import { Doc, Theme } from 'modules/api'
import { EditorCore } from 'modules/tiptap_editor'
import { GlobalCardStyles } from 'modules/tiptap_editor/extensions/Card/GlobalCardStyles'

import { SIDEBAR_WIDTH } from '../constants'

const MODAL_BODY_CLASS = 'template-preview-modal-body'

export const TemplatePreviewMain = ({
  doc,
  theme,
  snapshotContent,
  setEditor,
}: {
  doc: Doc
  theme?: Theme
  snapshotContent: JSON
  setEditor: Dispatch<SetStateAction<Editor | undefined>>
}) => {
  return (
    <Flex
      w="100%"
      flex={1}
      direction="column"
      className={`${MODAL_BODY_CLASS}`}
      align="center"
      overflowY="auto"
      overflowX="auto"
      justifyContent={'flex-start'}
      h="100%"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="gray.200"
      css={{
        '--editor-width': `calc(100vw - ${SIDEBAR_WIDTH} - var(--chakra-sizes-32) - 2px)`,
      }}
    >
      <GlobalCardStyles />
      <EditorCore
        animationsEnabled={false}
        isStatic={false}
        key={doc.id}
        theme={theme ? theme : undefined}
        initialContent={snapshotContent}
        doc={doc as Doc}
        docId={doc.id}
        readOnly={true}
        scrollingParentSelector={`.${MODAL_BODY_CLASS}`}
        onCreate={({ editor }) => setEditor(editor)}
      />
    </Flex>
  )
}
