import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { useCallback, useEffect, useRef, useState } from 'react'

import {
  Doc,
  Theme,
  useGetDocWithSnapshotQuery,
  useGetThemesQuery,
} from 'modules/api'
import { useSyncCardsRedux } from 'modules/cards/sync'
import { SimplifiedDeckEntry } from 'modules/example_decks/types'
import { getDocIdForEnv } from 'modules/example_decks/utils'
import {
  SegmentEvents,
  useAnalytics,
  useAnalyticsEffect,
} from 'modules/segment'
import { useDuplicateDoc } from 'modules/tiptap_editor/utils/duplicate'
import SalPainting from 'publicImages/Sal-Painting-2x.png'
import { isSingleTabBrowser } from 'utils/deviceDetection'

import { SIDEBAR_WIDTH } from '../constants'
import { TemplatePreviewMain } from './TemplatePreviewMain'
import { TemplatePreviewSidebar } from './TemplatePreviewSidebar'
const MODAL_BODY_CLASS = 'template-preview-modal-body'

const TemplatePreviewSkeleton = () => {
  return (
    <>
      <ModalHeader>
        <Skeleton w="80%" h="48px" />
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody display="flex" overflow="hidden">
        <Flex flex={1}>
          <Center w="100%" bg="gray.100">
            <Spinner />
          </Center>
        </Flex>
        <Flex w={SIDEBAR_WIDTH} ml={4} direction="column">
          <Skeleton w="100%" h="48px" mb={4} />
          <Skeleton w="50%" h={8} mb={4} />
          <Skeleton w="100%" h={36} mb={4} />
          <Skeleton w="100%" h={36} mb={4} />
          <Skeleton w="100%" h={36} mb={4} />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Skeleton w={48} h="42px" />
      </ModalFooter>
    </>
  )
}

const TemplatePreviewError = ({
  onClose,
  heading,
  errorMessage,
  onBackClick,
}: {
  onClose: () => void
  heading?: string
  errorMessage?: string
  onBackClick?: () => void
}) => {
  return (
    <>
      <ModalHeader>
        {Boolean(onBackClick) && (
          <IconButton
            aria-label="Back"
            icon={<ArrowBackIcon />}
            variant="ghost"
            onClick={onBackClick}
            mr={2}
          />
        )}
        {heading}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody display="flex" overflow="hidden" className={MODAL_BODY_CLASS}>
        <Flex direction="column" justify="center" align="center" w="100%">
          <Image
            src={SalPainting.src}
            width="300px"
            alt="Sal, the Gamma mascot, perched on a rocket enjoying some screentime"
            mb={6}
          />
          <Heading size="sm" mb={2} textAlign="center">
            <Trans comment="If this doesn't translate this well, you can just write 'Oops'">
              Oops, this is embarrassing.
            </Trans>
          </Heading>
          <Text fontSize="md" color="gray.400" textAlign="center">
            <Trans>
              We're sorry we couldn't load the template. Please try again later.
            </Trans>
            <br />
            {Boolean(errorMessage) && errorMessage}
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button variant="solid" onClick={onClose}>
          <Trans>Close</Trans>
        </Button>
      </ModalFooter>
    </>
  )
}

type OnCloneArgs = { openThemeEditor?: boolean }

export const TemplatePreview = ({
  previewDeck,
  onBackClick,
  forceChannelId,
  onClose,
}: {
  previewDeck?: SimplifiedDeckEntry
  onBackClick?: () => void
  forceChannelId?: string | null
  onClose: () => void
}) => {
  const [isCloning, setIsCloning] = useState<boolean>(false)
  const duplicateDoc = useDuplicateDoc()
  const docIdToUse = getDocIdForEnv(previewDeck)
  useAnalyticsEffect((analytics) => {
    analytics.track(SegmentEvents.DOC_VIEWED, {
      doc_id: docIdToUse,
    })

    analytics.track(SegmentEvents.EXAMPLE_VIEWED, {
      doc_id: docIdToUse,
    })
  })
  const {
    data: docData,
    loading: docLoading,
    error: docError,
  } = useGetDocWithSnapshotQuery({
    variables: {
      id: docIdToUse as string,
    },
    skip: !docIdToUse,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })
  const { data: globalThemeData, loading: loadingGlobalThemes } =
    useGetThemesQuery({
      variables: {
        // @ts-ignore
        workspaceId: null,
        archived: false,
      },
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
    })

  const globalThemes = globalThemeData?.themes || []

  const analytics = useAnalytics()
  const doc = docData?.doc
  const docTheme = doc?.theme
  const [theme, setTheme] = useState<Theme | undefined>(docTheme)
  const hasThemeBeenSetRef = useRef<boolean>(false)
  const [templateEditor, setTemplateEditor] = useState<Editor>()
  const isLoading = !previewDeck || docLoading
  const error = docError || docError
  const snapshotContent = doc?.currentSnapshot?.content.default

  useSyncCardsRedux(docData?.doc?.cards, templateEditor)
  const themesToRender = [...globalThemes.filter((t) => t.id !== docTheme?.id)]

  useEffect(() => {
    // Set the theme once, when the doc is loaded
    if (docTheme && !hasThemeBeenSetRef.current) {
      setTheme(docTheme)
      hasThemeBeenSetRef.current = true
    }
  }, [docTheme])

  useEffect(() => {
    // Update the accent images when the theme changes
    if (templateEditor) {
      const accentBackgrounds = theme?.config.accentBackgrounds
      if (!accentBackgrounds || accentBackgrounds.length === 0) {
        if (snapshotContent) {
          // reset the template content instead of removing accent images
          templateEditor.commands.setContent(snapshotContent)
        }
        return
      }
      templateEditor.commands.updateThemeAccentImages(accentBackgrounds)
    }
  }, [snapshotContent, templateEditor, theme])

  const onThemeClick = useCallback(
    (nextTheme: Theme) => {
      setTheme(nextTheme)
      analytics?.track(SegmentEvents.IN_APP_TEMPLATE_THEME_CLICKED, {
        theme_id: nextTheme.id,
      })
    },
    [analytics]
  )
  const onClone = useCallback(
    ({ openThemeEditor }: OnCloneArgs) => {
      if (!doc || !snapshotContent) return

      setIsCloning(true)

      if (openThemeEditor) {
        analytics?.track(SegmentEvents.IN_APP_TEMPLATE_CREATE_THEME_CLICKED, {
          source_doc_id: doc.id,
        })
      }
      // TODO: Allow duplicateDoc to take a Theme parameter
      // https://linear.app/gamma-app/issue/G-3700/[be]-[fe]-allow-duplication-with-a-newdifferent-theme
      duplicateDoc({
        docId: doc.id,
        initialContent: snapshotContent,
        channelId: forceChannelId,
        newWindow: !isSingleTabBrowser(),
        onSuccess: onClose,
        openThemeEditor,
        source: openThemeEditor
          ? 'template_modal_preview'
          : 'template_modal_preview_create_theme',
      })
    },
    [doc, snapshotContent, duplicateDoc, forceChannelId, analytics, onClose]
  )

  if (isLoading) {
    return <TemplatePreviewSkeleton />
  }

  if (error || !previewDeck || !doc || !snapshotContent) {
    if (error) {
      console.error(
        `[TemplatePreview] Error loading template: ${error.message}`
      )
    }
    return (
      <TemplatePreviewError
        onClose={onClose}
        onBackClick={onBackClick}
        heading={
          previewDeck?.title ? previewDeck.title : 'Error loading template'
        }
        errorMessage={error?.message}
      />
    )
  }

  return (
    <>
      <ModalHeader>
        {Boolean(onBackClick) && (
          <IconButton
            aria-label="Back"
            icon={<ArrowBackIcon />}
            variant="ghost"
            onClick={onBackClick}
            mr={2}
          />
        )}
        {previewDeck.title}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody display="flex" overflow="hidden">
        <TemplatePreviewMain
          doc={doc as Doc}
          snapshotContent={snapshotContent}
          theme={theme}
          setEditor={setTemplateEditor}
        />
        <TemplatePreviewSidebar
          setTheme={onThemeClick}
          currentTheme={theme}
          templateTheme={docTheme}
          themes={themesToRender}
          onCloneClick={onClone}
          isCloneButtonDisabled={isCloning}
        />
      </ModalBody>
    </>
  )
}
